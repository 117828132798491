<template>
    <div class="final-panel-wrapper">
        <div class="video-text-wrapper mt-1">
            <div class="final-video-preview">
                <video :src="previewSource" controls height="250" muted="true" disablePictureInPicture></video>
            </div>
          <div class="flex-column final-desc-text mb-2">
              <div class="final-desc-filepath"> <span>{{ scene.filePath }} </span></div>
            <div v-if="$route.path.indexOf('/new/') !== -1">
              You have completed the workspace configuration.  Once you click the
              "RUN ANALYSIS" button your video will be submitted to the Databricks cluster
              to run full object detection processing - this will take a little while.
              When it is finished, you will be able to view the full results of the
              analysis.
            </div>
            <div v-if="$route.path.indexOf('/new/') === -1">
              You have updated the workspace configuration.  Once you click the
              "RE-ANALYZE" button, your video will be re-analyzed based on the
              object detection and tracking information already extracted from
              the video, but using your updated values to inform the
              new analysis.
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "finalPanel",
    props: {
    },
    data() {
      return {
          scene: null,
      }
    },
    beforeMount() {
        this.scene = this.$parent.modelObject.scene;
        if(this.scene){
            if(!this.$store.state.azureBlobData.data.sas.key || this.$store.state.azureBlobData.data.sas.container !== this.scene.containerName) {
                this.$store.dispatch('getSharedAccessSignature', this.scene.containerName)
            }
        }

    },
    computed: {
      previewSource() {
        if(this.$store.state.azureBlobData.data.sas.key && this.$store.state.settings.storageDetails.storageAccount && this.scene.containerName && this.scene.filePath){
            return 'https://' + this.$store.state.settings.storageDetails.storageAccount + '.blob.core.windows.net/' + this.scene.containerName  + '/' + this.scene.filePath + '?' + this.$store.state.azureBlobData.data.sas.key;
        }
        return null;
      }
    }
}

</script>
<style lang="scss">
    .final-panel-wrapper{
        margin: 0 25px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .video-text-wrapper{
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: row;
            .final-video-preview {
                padding-top: 25px;
                min-height: 250px;

            }
        }
    }

    @media screen and (max-width: 1300px) {
        .video-text-wrapper {
            flex-direction: column!important;
        }
    }
    @media screen and (min-width: 1301px) {
        .final-video-preview {
            max-width: 60%;
            > video {
                height: 80%;
                max-width: 100%;
            }
        }
    }
.content {
  opacity: 1.0 !important;
}

.final-desc-filepath {
  margin-bottom: 30px;
  font-weight: bold;
}
.final-desc-text {
  display: flex;
  padding-left: 20px;
  padding-top: 25px;
  font-size: 14px;
  color: white;
}

</style>
